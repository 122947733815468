import React from 'react';
import Cards from 'react-credit-cards';
import WrapWithContainer from '../CommonWrapper';
import { MyCreditCardContainer } from './style';

const MyCreditCard = ({ className, ...otherProps }) => (
    <MyCreditCardContainer className={className} >
      <Cards {...otherProps}/>
    </MyCreditCardContainer>
  );

export default WrapWithContainer(MyCreditCard);
