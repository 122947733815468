import styled from 'styled-components';

export const MyCreditCardContainer = styled.div`
  .rccs {
    max-width: 290px;
    width: 100%;

    .rccs__card {
      max-width: 290px;
      width: 100%;
    }
  }
`;
